import * as THREE from "three";
import { useEffect, useRef, useState } from "react";
import TWEEN from "@tweenjs/tween.js";
import { GlitchMode } from "postprocessing"; // https://github.com/pmndrs/postprocessing
import Emittery from "emittery"; // https://www.npmjs.com/package/emittery
import Scene3D from "./Scene3D";
import "./App.css";
import { actionMidi, startMidi } from "./lib/midi";
import { getRandomItem } from "./lib/utils";
import Recorder from "./lib/Recorder";

Emittery.isDebugEnabled = true;
let emitter = new Emittery({ debug: { name: "myEmitter1" } });

let tempoInterval = 0;
var BPM: any = require("bpm");
const getRandomOffset: any = (arr: Array<any>, current: any): any => {
  const off = Math.floor(Math.random() * arr.length);
  return off !== current ? off : getRandomOffset(arr, current);
};

let noteTweens: any = [];
let speed: number = 500;
let MyScene: Scene3D;

var b = new BPM();

const colors = [
  0xacb6e5, 0x74ebd5, 0xffbb00, 0x00bbff, 0xff00ff, 0xffff00, 0xff5555,
  0xff55ff, 0x00bbff, 0x55ff55, 0x5555ff,
];

const notes = ["C3", "D3", "E3", "F3", "G3", "A3", "B3"];

function init() {
  MyScene = new Scene3D(notes, colors);
}

function App() {
  const [pos, setPos] = useState(0);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [bpm, setBpm] = useState(0);
  const requestRef = useRef<any>();
  const bpmTick = useRef<any>();

  let xx = 0;
  let yy = 0;
  let popos = 0;

  // controllers callbacks

  const callback20 = (value: any) => {
    yy = (-1 / 2 + value) / 10;
    setY(yy);
  };
  const callback19 = (value: any) => {
    xx = (-1 / 2 + value) / 10;
    setX(xx);
  };
  const callback18 = (value: any) => {
    popos = (-1 / 2 + value) / 10;
    setPos(popos);
  };
  const ambiantCallback = (value: any) => {
    MyScene.ambientLight.intensity = value * 3;
  };

  const callbackSpeed = (value: any) => {
    speed = 1000 - value * 1000;
  };

  // Emitters with notes

  emitter.on("resetPos", (data: any) => {
    if (data.aftertouch) {
      callback20(0.5);
      callback19(0.5);
      callback18(0.5);
    }
  });
  emitter.on("allNotes", (data: any) => {
    const [note, attack, aftertouch] = data;
    for (let n of notes) {
      if (noteTweens[n]) {
        TWEEN.remove(noteTweens[n]);
      }
      MyScene.noteObjects[n].intensity = attack;
      // noteCallback(n, attack, aftertouch);
      emitter.emit("noteCallback", [n, attack, aftertouch]);
    }
  });
  emitter.on("pitchbend", (value: number) => {
    yy = value / 50;
    setY(yy);
  });

  emitter.on("noteCallback", (data: any) => {
    const [note, attack, aftertouch] = data;
    const twn = noteTweens[note];
    if (twn) {
      TWEEN.remove(twn);
    }
    const msh: THREE.RectAreaLight = MyScene.noteObjects[note];
    if (msh) {
      if (aftertouch) {
        msh.intensity = attack;
        msh.height = attack * 50;
      } else {
        noteTweens[note] = new TWEEN.Tween(MyScene.noteObjects[note])
          .to({ intensity: 0, height: 0 }, 1000)
          .easing(TWEEN.Easing.Sinusoidal.InOut)
          .end()
          .start();
      }
    }
  });

  emitter.on("megaLightFlash", (data: any) => {
    const [note, attack, aftertouch] = data;
    if (aftertouch) {
      MyScene.megaLight.intensity = 0.3;
    } else {
      new TWEEN.Tween(MyScene.megaLight)
        .to({ intensity: 0 }, 1000)
        .easing(TWEEN.Easing.Sinusoidal.InOut)
        .end()
        .start();
    }
  });

  emitter.on("backFlash", (data: any) => {
    const [note, attack, aftertouch] = data;
    if (aftertouch) {
      const color = note === "C2" ? getRandomItem(colors) : 0xffffff;
      MyScene.scene.background = new THREE.Color(color);
    } else {
      new TWEEN.Tween(MyScene.scene)
        .to({ background: new THREE.Color(0x000000) }, 300)
        .easing(TWEEN.Easing.Cubic.Out)
        .start();
    }
  });

  emitter.on("pixelateNote", (data: any) => {
    const [note, attack, aftertouch] = data;
    if (aftertouch) {
      MyScene.pixelate.granularity = 64;
    } else {
      noteTweens[note] = new TWEEN.Tween(MyScene.pixelate)
        .to({ granularity: 0 }, 300)
        .easing(TWEEN.Easing.Sinusoidal.InOut)
        .start();
    }
  });

  emitter.on("bloomNote", (data: any) => {
    const [note, attack, aftertouch] = data;
    if (aftertouch) {
      MyScene.bloom.intensity = 20;
    } else {
      noteTweens[note] = new TWEEN.Tween(MyScene.bloom)
        .to({ intensity: 4 }, 300)
        .easing(TWEEN.Easing.Sinusoidal.InOut)
        .start();
    }
  });

  emitter.on("tapeBPM", (data: any) => {
    const [note, attack, aftertouch] = data;
    if (aftertouch) {
      let tap = b.tap();
      if (tap.count > 4) {
        b.reset();
        tap = b.tap();
      }
      setBpm(tap.avg);
      window.clearInterval(tempoInterval);
      if (tap.ms && tap.ms > 100) {
        tempoInterval = window.setInterval(() => {
          bpmTick.current.style.opacity = 1;

          new TWEEN.Tween(bpmTick.current.style)
            .to({ opacity: 0 }, tap.ms)
            .easing(TWEEN.Easing.Sinusoidal.Out)
            .start();
        }, tap.ms);
      }
    }
  });

  emitter.on("glitchNote", (data: any) => {
    const [note, attack, aftertouch] = data;
    if (aftertouch) {
      MyScene.glitch.mode = GlitchMode.CONSTANT_WILD;
    } else {
      setTimeout(() => {
        MyScene.glitch.mode = GlitchMode.DISABLED;
      }, 200);
    }
  });

  // Animation loop
  const animationLoop = (time: number) => {
    TWEEN.update();

    MyScene.composer.render();

    MyScene.uniforms["time"].value = time / 500;

    if (MyScene.groupMsh) {
      MyScene.groupMsh.rotation.y -= 0.1;
    }
    MyScene.sceneObjects.forEach((object: THREE.Mesh, r: any) => {
      const delta = time / speed;
      object.rotation.x += (popos + 1 / 100) * 2;
      object.rotation.y += (popos / 2 + 1 / 200) * 2;
      object.position.y =
        (Math.sin(delta + r) * (yy * 30) * MyScene.nbCube) / 2;
      object.position.z =
        (Math.sin(delta + r) * (xx * 30) * MyScene.nbCube) / 2;
    });
    requestRef.current = requestAnimationFrame(animationLoop);
  };

  // Recorder

  const recorder = new Recorder(actionMidi);

  // Configuration Midi

  const notesConf: any = {
    C2: "backFlash",
    "C#2": "backFlash",
    D2: "allNotes",
    "D#2": "glitchNote",
    E2: "pixelateNote",
    "E#2": "resetPos",
    F2: "bloomNote",
    "F#2": "megaLightFlash",
    G2: "resetPos",
    C3: "noteCallback",
    D3: "noteCallback",
    E3: "noteCallback",
    F3: "noteCallback",
    G3: "noteCallback",
    A3: "noteCallback",
    B3: "noteCallback",
    A4: "tapeBPM",
    B4: recorder.resetRecorder.bind(recorder),
    C5: recorder.recordAndPlay.bind(recorder),
  };

  const keyboardnote = [
    "a", // C2
    "z", // C#2
    "e", // D2
    "r", // D#2
    "t", // E2
    "y", // E#2
    "u", // F2
    "i", // F#2
    "o", // G2
    "w", // C3
    "x", // D3
    "c", // E3
    "v", // F3
    "b", // G3
    "n", // A3
    ",", // B3
    "@", // A4
    "&", // B4
    // '"', // C5 remove start recording and define it with 2 actions start/stop for keyboard
  ];

  const actionKeyboard = (event: any, aftertouch: boolean) => {
    let count = 0;
    for (let k in notesConf) {
      if (event.key === keyboardnote[count]) {
        // convert to midi note
        actionMidi({
          type: aftertouch ? "noteon" : "noteoff",
          note: {
            identifier: k,
            attack: 1,
          },
        });
      }
      count++;
    }
  };

  // OnLoad

  useEffect(() => {
    // MIDI
    startMidi(emitter, {
      controllers: {
        channels: {
          18: callback18,
          19: callback19,
          74: callback20,
          71: callbackSpeed,
          16: ambiantCallback,
        },
      },

      notes: notesConf,
      pitchbend: "pitchbend",
      debug: false,
      record: recorder.record.bind(recorder),
      recordNote: "C5",
    });

    document.addEventListener("keydown", (event: any) => {
      actionKeyboard(event, true);
    });
    document.addEventListener("keyup", (event: any) => {
      if (event.key === "é" && !recorder.isRecording) {
        recorder.recordAndPlay(false, false, true);
      } else if (event.key === '"' && recorder.isRecording) {
        recorder.recordAndPlay(false, false, false);
      } else {
        actionKeyboard(event, false);
      }
    });

    init();

    requestRef.current = requestAnimationFrame(animationLoop);
    return () => cancelAnimationFrame(requestRef.current);
  }, []);

  return (
    <>
      <div style={{ position: "absolute", top: 5, left: 5, color: "white" }}>
        Knob 2 : {y} <br />
        Knob 10: {pos}
        <br />
        Knob 11 : {x}
        <br />
        bpm : {bpm}
      </div>
      <span
        ref={bpmTick}
        style={{
          position: "absolute",
          top: 15,
          right: 15,
          background: "#FFBB00",
          height: 15,
          width: 15,
          borderRadius: 7,
          display: "block",
        }}
      />
      <div
        style={{ position: "absolute", bottom: 5, right: 5, color: "white" }}
      >
        <p>Tiny midi test by med/mandarine</p>
      </div>
    </>
  );
}

export default App;
